import { Skeleton, type SxProps } from '@mui/material';
import type {
  ProductListSeoLinkListFragmentFragment,
  SeoElementFragmentFragment,
} from '@packages/gql/generated/shopping/graphql';
import { Grid, Link, Stack, Typography, useMedia } from '@packages/shared';
import { LazyRender } from '../LazyRender';

const seoLinkStyles: SxProps = {
  display: 'block',
  color: 'text.primary',
  textDecoration: 'none',
  overflow: 'hidden',
  fontSize: 'body2.fontSize',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

type SeoElementLinkListType =
  | NonNullable<SeoElementFragmentFragment['alternateBrands']>
  | ProductListSeoLinkListFragmentFragment['links'];

const SeoElementLink = ({ link }: { link: SeoElementLinkListType[number] }) => {
  // check link is v2 to allow retrocompatibility with v1
  const isV2 = 'alternateText' in link;

  const alternateText = isV2 ? link.alternateText : link.alt;

  return (
    <Grid item xs={6} md={4} lg={2}>
      <Link title={alternateText ?? undefined} href={link.url!} key={link.url}>
        {alternateText}
      </Link>
    </Grid>
  );
};

export type SeoElementLinkListProps = {
  /**
   * A list of seo links with text and url to be aligned in a grid for search-engines.
   */
  linkList: SeoElementLinkListType;
  /**
   * The SEO relevant headline text
   */
  headline: string;
};

/**
 * SeoElementLinkList component renders a single list of search-engine relevant links in a grid.
 * */
export const SeoElementLinkList = ({ linkList, headline }: SeoElementLinkListProps) => {
  const headlineAlignment = useMedia({ mobile: 'center', tablet: 'left', desktop: 'left' });
  const linksPerRow = useMedia({ mobile: 2, tablet: 3, desktop: 6 });

  return (
    <Stack spacing={1.5}>
      <Typography component="div" variant="h3" align={headlineAlignment}>
        {headline}
      </Typography>
      <LazyRender
        fallback={
          <Stack role="group" spacing={1.5}>
            {[...new Array(Math.ceil(linkList.length / linksPerRow))].map((_, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <Skeleton key={`SeoElementLinkListSkeleton${idx}`} />
            ))}
          </Stack>
        }
      >
        <Grid role="group" container spacing={1.5} sx={{ a: { ...seoLinkStyles } }}>
          {linkList.map((link, index) => (
            // in this case we can use index as the key, because the list is static and will not be re-ordered or manipulated during lifecycle (url and alt are not unique!)
            // eslint-disable-next-line react/no-array-index-key
            <SeoElementLink link={link} key={index} />
          ))}
        </Grid>
      </LazyRender>
    </Stack>
  );
};
