import { useEffect, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSetAtom } from 'jotai';

import { Typography, Box } from '@packages/shared';

import { useSearchFieldTracking } from '../useSearchFieldTracking';
import { useItemSelection } from '../useItemSelection';
import { InspiringSearchSuggestionsItem } from './InspiringSearchSuggestions.Item';
import { isInspiringSearchOnboardingOpenAtom } from '../../../Header.atoms';
import { useInspiringSearchData } from '../useInspiringSearchData';
import { shuffled } from '../shuffled';

interface InspiringSearchProps {
  /** Selected suggest keyboard item */
  cursor: number;
  /** Callback that is called when the user clicks the arrow button to accept a inspired search item as the current input value without immediately triggering search buddy */
  onAccept?: (value: string) => void;
  /** Update input value */
  setValue: (value: string, displayOnly?: boolean) => void;
}

const messages = defineMessages({
  inspiredSearchTitle: {
    id: 'inspiring.search.title',
    defaultMessage: 'Inspirierende Suche',
  },
  inspiredSearchLink: {
    id: 'inspiring.search.link',
    defaultMessage: 'Erfahre mehr zur inspirierenden Suche',
  },
});

export const InspiringSearchSuggestions = ({
  onAccept,
  setValue,
  cursor,
}: InspiringSearchProps) => {
  const setIsOnboardingModalOpen = useSetAtom(isInspiringSearchOnboardingOpenAtom);

  const { formatMessage } = useIntl();

  const { dispatchSearchGtmEvent } = useSearchFieldTracking();

  const { exampleQueries, isLoading } = useInspiringSearchData();

  const randomSuggestions = useMemo(() => shuffled(exampleQueries).slice(0, 3), [exampleQueries]);

  const [selectedItem, selectedIndex] = useItemSelection(cursor, randomSuggestions);

  useEffect(() => {
    setValue(selectedItem ?? '', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  return (
    <>
      <Box component="li" sx={{ p: 1, backgroundColor: 'grey.main' }}>
        <Typography sx={{ fontWeight: 600, mt: 0.5, mr: 4, mb: 0.75, ml: 0.75 }}>
          {formatMessage(messages.inspiredSearchTitle)}
        </Typography>
      </Box>
      <Box
        component="ul"
        sx={{
          backgroundImage: 'url("/search-assets/inspiring-search-background.svg")',
          backgroundSize: '100% 100%',
        }}
      >
        {/* TODO at some point, when refactoring this and SearchHistory, use Suspense */}
        {isLoading
          ? Array.from({ length: 3 })
              .map((_, i) => `example-placeholder${i}`)
              .map((id) => <InspiringSearchSuggestionsItem.Fallback key={id} />)
          : randomSuggestions.map((entry, i) => (
              <InspiringSearchSuggestionsItem
                selected={selectedIndex === i}
                onClick={() => dispatchSearchGtmEvent(entry)}
                onArrowClick={() => onAccept?.(entry)}
                url={`/s/${entry}/`}
                key={entry}
                label={entry}
              />
            ))}
        <InspiringSearchSuggestionsItem
          testId="inspiring search information"
          selected={selectedIndex === randomSuggestions.length}
          label={formatMessage(messages.inspiredSearchLink)}
          withAdoptButton={false}
          onClick={() => setIsOnboardingModalOpen(true)}
          sx={{ textDecoration: 'underline', ':hover': { textDecoration: 'underline' } }}
        />
      </Box>
    </>
  );
};
