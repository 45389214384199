import dynamic from 'next/dynamic';

import { Skeleton } from '@packages/shared';

export const DynamicSearchField = dynamic<{
  autofocus?: boolean;
  onSearch?: () => void;
  isolated?: boolean;
}>(() => import('./SearchField'), {
  ssr: false,
  loading: () => <Skeleton variant="rectangular" width="100%" height={44} />,
  suspense: true,
});
