import type { ProductConfig } from './product/product.types';
import type { InstallmentCalculatorConfig } from './product/installmentCalculator.types';
import type { CustomerRatingsConfig } from './product/customerRatings.types';

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T[P] extends ReadonlyArray<infer U>
      ? ReadonlyArray<DeepPartial<U>>
      : DeepPartial<T[P]>;
};

export type CookieCategory = 'C0001' | 'C0003' | 'C0002' | 'C0004' | 'C0005';

export type GeoCountryCode = string | undefined;
export type Flags = 'de' | 'ch' | 'fr' | 'at' | 'it' | 'nl';
export type Language = 'de' | 'fr' | 'en' | 'nl';
export type Locale = 'de-DE' | 'fr-CH' | 'de-CH' | 'de-AT' | 'nl-NL';

export type PreRenderAtBuldTimeVariants = 'type' | 'cmsPage';

export enum Environment {
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export type Config = {
  host: {
    name: string;
    domain: string;
  };
  i18n: {
    defaultLocale: Locale;
    languages: Language[];
    locales: Locale[];
  };
  clientId: string;
  tenantId: string;
  styleVariant: 'default' | 'dark';
  company: {
    name: string;
    seoTitleName: string;
  };
  captcha: {
    recaptchaSessionToken: string;
  };
  assets: {
    cmsImage: {
      imageQuality: number;
      imageOptimizationEnabled: boolean;
      uriTransformSource: RegExp;
      uriTransformEndpoint: string;
    };
  };
  apiEndpoints: {
    search: string;
    searchFilter: string;
    searchSeo: string;
    suggest: string;
    historyGet: string;
    historyApiNew: string;
    historyDelete: string;
    historyDeleteAll: string;
    historyDeleteAllNew: string;
    historyJoinWithFi: string;
    customer: string;
  };
  insiderPushNotifications: {
    enabled: boolean;
    apiKey?: string;
    partnerName?: string;
    partnerId?: number;
  };
  dynamicYieldEnabled: boolean;
  hermesShopFinder: HermesShopFinderConfig;
  product: ProductConfig;
  installmentCalculator: InstallmentCalculatorConfig;
  header: HeaderConfig;
  usp: UspConfig;
  search: SearchConfig;
  order: OrderConfig;
  staticContent: StaticContentConfig;
  footer: FooterConfig;
  banner: BannerConfig;
  nlSheetTexts: NlSheetTextsConfig;
  serviceLayer: ServiceLayerConfig;
  seoContent: SeoContentConfig;
  survey: SurveyConfig;
  sovendus: {
    enabled: boolean;
  };
  chatBot: ChatBotConfig;
  promotionBanner: PromotionBannerConfig;
  voucher: VoucherConfig;
  recommendations: RecommendationsConfig;
  searchResultUserFeedback: FeedbackConfig;
  shoppingApp: {
    pathsToRenderChildrenOnly: string[];
  };
  navigation: {
    firstLevel: {
      color: string;
      backgroundColor?: string;
      border: string;
    };
    secondLevel?: {
      color: string;
      backgroundColor?: string;
      border: string;
    };
    loadSliced: boolean;
    firstLevelFont?: string;
    firstLevelFontSize?: string;
  };
  forms: {
    useOptimizely: boolean;
    apiAgnitasUrl: {
      enabled: boolean;
      environmentRelated: boolean;
      values: {
        url: string;
        useDOI: boolean;
        sendAdditionalSubscribe: boolean;
      };
    };
    contact: {
      selectOptionsSubject: { label: string; value: string }[];
      enableFileUpload: boolean;
    };
    newsletterUnsubscribe: {
      fields: CmsFormsFieldConfig[];
      useDedicatedSubmitButtonTitleFor?: string[];
    };
    newsletterSubscribe: {
      [key in Language]: { unsubscribeLink: string; voucherLink: string };
    };
    newsletterSheet: NewsletterSheetConfig;
    validation: {
      rules: {
        customerId: {
          regex: RegExp;
          errorMessage: {
            id: string;
            defaultMessage: string;
          };
        };
        fileUpload: {
          fileLimit: number;
          maxFileSizeMb: number;
          maxFileSizeSummaryMb: number;
          acceptedFormats: string;
          regexToCheckImageFormats: RegExp;
          regexAcceptedUploadFormats: RegExp;
          indicators: {
            showFileSize: boolean;
            showFileSizeSummary: boolean;
          };
        };
      };
    };
  };
  enableCustomerNrLogin: boolean;
  headerSlots: {
    localeSwitcher: boolean;
    payback: boolean;
    warehouse: boolean;
    joe: boolean;
    serviceApi?: string;
  };
  // NOTE this is currently used for a bunch of shitty workarounds to keep imwalkingde barely alive, all usages should be removed as soon as it is allowed
  lifeSupportForBasicallyDeadTenant: boolean;
  headerLinks: {
    [key in Locale]: {
      serviceLink: string;
    };
  };
  appBanner: {
    enabled: boolean;
    appUrl: string;
  };
  tracking: TrackingConfig;
  abTesting: AbTestingConfig;
  testPanel: TestPanelConfiguration;
  externalScript?: {
    kuecheCo?: {
      key: string;
    };
  };
  video: {
    video_youtube: { url: string; provider: string; name: string };
  };
  headerInformation: {
    [key in Language]?: {
      description: string;
      title: string;
      canonical: string;
      robots: string;
    };
  };
  customerRatings: CustomerRatingsConfig;
  customerCentricFeedback: {
    enabled: boolean;
    tenantId: string;
    surveyIdStorefront: string;
    surveyIdSearch: string;
    surveyIdDetailview: string;
  };
  dynamicYieldTracking: {
    key: string;
    endpoint: string;
    endpointClientSide: string;
  };
  geoCountryLayer: {
    enabled: boolean;
    flag?: Flags;
    img: {
      path: string;
      alt: string;
      title: string;
    };
    alternatives?: Array<AlternativesShops>;
  };
  redirectOverlay: {
    enabled: boolean;
    redirectParam: string;
    redirectLp: {
      de: string;
      fr?: string;
    };
  };
  lounge: {
    icon?: (lang: string) => string;
  };
  bookings: {
    overview: {
      hasExplicitMaturities: boolean;
      showOpenPayment: 'none' | 'minimal-payment' | 'installments' | 'sum-and-open';
    };
  };
  priceFormat: PriceFormat;
};

export type InternalPageMetadata = {
  title?: string;
};

export type SchemaOrgOrganisation = {
  [key in Locale]: {
    addressCountry: string;
    addressRegion: string;
    addressLocality: string;
    alternateName: string[];
    areaServed: string;
    contactEmail: string;
    contactUrl: string;
    description: string;
    email: string;
    hoursAvailable: string[];
    legalName: string;
    logo: string;
    name: string;
    postalCode: string;
    privacyNoticePath: string;
    potentialAction: {
      target: string;
    };
    productSupported: string;
    sameAs: string[];
    streetAddress: string;
    telephone: string;
    url: string;
  };
};

export type AlternativesShops = {
  flag?: Flags;
  shops?: Array<AlternativesShopsItem>;
};

export type AlternativesShopsItem = {
  link?: string;
  title?: string;
  closeLayer?: boolean;
};

export interface TrackingConfig {
  soastaId: string;
  tagmanagerId: string;
  tagmanagerDomain?: string;
  /** Full script url. Overwrites tagmanagerId and tagmanagerDomain config */
  tagmanagerSrc?: string;
  googleSiteVerificationID?: string[];
  msvalidateID?: string;
  schemaOrgOrganisation: SchemaOrgOrganisation;
  pinterestRichPinVerificationID?: string;
}

export interface AbTestingConfig {
  serverSideEnrollment: {
    /**
     * List of all active test ids all users should be automatically enrolled in on the server-side.
     *
     * Enrollment on the server side has the benefits that it can be used for data fetching even on the first request, but the drawbacks that it will delay the first response on a new session/visit to fetch the info.
     */
    activeTestIds: string[];
  };
}

export interface TestPanelConfiguration {
  apps?: TestPanelApp[];
  backends?: TestPanelApp[];
  systems?: TestPanelSystem[];
}

export interface TestPanelApp {
  appLabel: string;
  appName: string;
  defaultBranch: string;
  projectId: string;
  featureCookieName: string;
  hasPathQuerySyntax?: boolean;
}

export interface TestPanelSystem {
  appLabel: string;
  appName: string;
  connector: 'apiKeyManager';
  systems: Record<string, string>;
  featureCookieName?: string;
  defaultBranch?: string;
}

export interface ExternalService {
  apiKey?: string;
  apiUrl?: string;
  consentGroup?: CookieCategory;
  containerName?: string;
  customerId?: string;
  debugEnabled?: boolean;
  enabled?: boolean;
  moreInformationLink?: string;
  scriptUrl?: string;
}

export interface PrerenderAtBuildTime {
  params: {
    [key in PreRenderAtBuldTimeVariants]?: string[];
  };
  locale: string;
}

export type PersonalizationSegmentSource = 'legacy' | 'baur' | 'unito';

export type AdServerDisabledConfig = {
  mode: 'disabled';
  mkzBlacklist: number[];
};

export type AdServerContainerOnlyConfig = {
  mode: 'containerOnly';
  mkzBlacklist: number[];
};

export type AdServerDoubleClickConfig = {
  mode: 'doubleClick';
  adServerClientId: string;
  adServerCustomerName: string;
  mkzBlacklist: number[];
};

export type AdServerConfig =
  | AdServerDisabledConfig
  | AdServerContainerOnlyConfig
  | AdServerDoubleClickConfig;

export type SearchConfig = {
  personalizationSegmentSource: PersonalizationSegmentSource;
  adServer: AdServerConfig;
  hideTelekomIcon: boolean;
  isInspiringSearchEnabled: boolean;
  /** Without trailing slash, so append full pathnames like `/` or `/foo.json` */
  inspiringSearchDataBucket: string;
};

export type StaticContentConfig = {
  fetchSettings: {
    timeout: number;
  };
  apiEndpoints: {
    jsonApi: string;
    bucket: string;
    webappJson: string;
  };
  ssg: {
    revalidateInterval: number;
    prerenderAtBuildTime: PrerenderAtBuildTime[];
  };
  cmsFontToTheme?: {
    [key: string]: {
      fontWeight:
        | 'fontWeightSemiBold'
        | 'fontWeightBold'
        | 'fontWeightBlack'
        | 'fontWeightLight'
        | 'fontWeightMedium';
      fontFamily?: string;
      fontStyle?: 'italic';
    };
  };
};

export type HeaderConfig = {
  hasNewNavigation: boolean;
  hasSmallerFontSize: boolean;
  desktopNavigationMode: 'click' | 'hover';
};

export type UspConfig = {
  isEnabled: boolean;
};

export type FooterConfig = {
  fetchSettings: {
    timeout: number;
  };
  apiEndpoints: {
    jsonApi: string;
    bucket: string;
    deDupingInterval: number;
  };
  ssg: {
    revalidateInterval: number;
    prerenderAtBuildTime: PrerenderAtBuildTime[];
  };
};

export type RecommendationsConfig = {
  apiEndpoint: string;
  provider: 'prudsys' | 'empiriecom';
};

export type FeedbackConfig = {
  appUrl?: string;
  enabled: boolean;
};

export type BannerConfig = {
  apiEndpoints: {
    bucket: string;
  };
  ssg: {
    revalidateInterval: number;
    prerenderAtBuildTime: PrerenderAtBuildTime[];
  };
};

export type NlSheetTextsConfig = {
  apiEndpoints: {
    bucket: string;
  };
  ssg: {
    revalidateInterval: number;
    prerenderAtBuildTime: PrerenderAtBuildTime[];
  };
};

export type ServiceLayerConfig = {
  apiEndpoints: {
    api: string;
    bucket: string;
    bucketPages: string;
  };
  ssg: {
    revalidateInterval: number;
    prerenderAtBuildTime: PrerenderAtBuildTime[];
  };
};

export type SurveyConfig = {
  apiEndpoints: {
    bucket: string;
  };
};

export type HermesShopFinderConfig = {
  url: string;
  rParam: string;
};

export type NewsletterSheetConfig = {
  enabled: boolean;
};

export type ChatBotConfig = {
  enabled: boolean;
  key: string;
  includePaths: string[];
  excludePaths: string[];
  visibility: {
    main: boolean;
    button: boolean;
    notifications: boolean;
  };
};

export type SeoContentConfig = {
  apiEndpoints: {
    bucket: string;
  };
};

export type PromotionBannerConfig = {
  apiEndpoints: {
    jsonApi: string;
    bucketPromotion: string;
    bucketEmptyPromotions: string;
    bucketContentSnippet: string;
  };
  abTest?: string;
};

export type CmsFormsFieldOptionsConfig = {
  id: string;
  value: string;
  messageKey: string;
};

export type CmsFormsFieldConfig = {
  id: string;
  type?: string;
  defaultValue?: string;
  options?: CmsFormsFieldOptionsConfig[];
  formType?: 'input' | 'radio' | 'select' | 'text' | 'email' | 'number' | 'password' | 'date';
  messageKeyPlaceholder?: string;
  additionalForm?: CmsFormsFieldConfig;
  showOnlyWhenWhatSelected?: string;
};

export type VoucherConfig = {
  apiEndpoints: {
    api: string;
    bucket: string;
  };
};

export type LocalizedPath = {
  [key in Locale]?: string;
} & {
  default: string;
};

export type OrderSealNameType =
  | 'trustedShops'
  | 'ehi'
  | 'staatswappen'
  | 'handelsverband'
  | 'ecommerce'
  | 'oeonline'
  | 'dqs';

export type OrderSealsConfig = {
  [key in OrderSealNameType]: {
    enabled: boolean;
    url: string;
  };
};

export type OrderAssetsConfig = {
  seals: {
    getSealImage: ({
      language,
      sealName,
    }: {
      language: string;
      sealName: OrderSealNameType;
    }) => string;
  };
  premium: {
    getPremiumImage: ({ language, device }: { language: string; device: string }) => string;
    getPremiumIcon?: ({ language }: { language: string }) => string;
  };
  gifs: {
    success: {
      url: string;
    };
  };
};

type OrderPaybackType = {
  enabled: boolean;
  link?: string;
  cardNumberLength?: number;
  cardNumberHintUrl?: string;
};

export type OrderConfig = {
  paths: {
    basket: LocalizedPath;
    login: LocalizedPath;
    delivery: LocalizedPath;
    changeDelivery: LocalizedPath;
    payment: LocalizedPath;
    changePayment: LocalizedPath;
    paymentprotection: LocalizedPath;
    paymentprotectionupdate: LocalizedPath;
    delayedPayment: LocalizedPath;
    premium: LocalizedPath;
    checkandorder: LocalizedPath;
    orderconfirmation: LocalizedPath;
    noOrder: LocalizedPath;
    afterPayment: LocalizedPath;
    myAccountOverview: LocalizedPath;
    myAccountPersonalData: LocalizedPath;
    myAccountOrders: LocalizedPath;
    myAccountBookings: LocalizedPath;
    myAccountAddresses: LocalizedPath;
    wishlist: LocalizedPath;
    paylink: LocalizedPath;
  };
  externalUrls: {
    agb: LocalizedPath;
    joe: LocalizedPath;
  };
  layers: {
    servicelayer: LocalizedPath;
  };
  joe: {
    enabled: boolean;
  };
  premium: {
    enabled: boolean;
  };
  payment: {
    americanExpress: boolean;
  };
  seals: OrderSealsConfig;
  assets: OrderAssetsConfig;
  hasCo2Compensation: boolean;
  hasFlexikontoAdvantageClub: boolean;
  payback: OrderPaybackType;
};

export type PriceFormat = 'Empiriecom' | 'NoDelimiterCurrencyAtTheEnd' | 'Iso';
