import { useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { WishlistButton, Notification, Portal, useI18n } from '@packages/shared';
import type { WishlistButtonProps, NotificationProps } from '@packages/shared';
import type { AvailabilityState } from '@packages/gql/generated/shopping/graphql';
import {
  type GlycerinAddToWishListPayload,
  type GTMEventGlycerinAddToWishList,
  type GlycerinRemoveFromWishListPayload,
  type GTMEventGlycerinRemoveFromWishList,
  type GlycerinWishListProduct,
  useTracking,
} from '@packages/tracking';
import { getBridge } from '@packages/utilities/src/getBridge';
import { useAuthMutation, useAuthQuery } from '@packages/gql';
import { AddToWishlistDocument } from '@packages/gql/generated/shopping/AddToWishlistDocument';
import { RemoveFromWishlistDocument } from '@packages/gql/generated/shopping/RemoveFromWishlistDocument';
import { WishlistSkusDocument } from '@packages/gql/generated/shopping/WishlistSkusDocument';

export type WishlistTrackingProps = Omit<GlycerinAddToWishListPayload, 'addedProducts'> &
  Omit<GlycerinRemoveFromWishListPayload, 'removedProducts'> & {
    products: GlycerinWishListProduct[];
  };

export type WishlistButtonWithApiProps = {
  /** The sku of the desired product */
  sku: string;
  /** Availability-state as string. Can be undefined if state is unknown. Can also be state 'UNSELECTED' which is identical to undefined. */
  availability?: AvailabilityState;
  /** name of the product to be added */
  productName?: string;
  /** icon only version
   * @default true
   */
  iconOnly?: boolean;
  trackingProps?: WishlistTrackingProps;
} & Omit<WishlistButtonProps, 'isActive'>;

const wishlistMessages = defineMessages({
  removeSuccess: {
    id: 'add-to-wishlist.remove-success',
    defaultMessage:
      '{productName, select, undefined {Der Artikel} other {{productName}}} wurde erfolgreich vom Merkzettel entfernt.',
  },
  removeError: {
    id: 'add-to-wishlist.remove-error',
    defaultMessage:
      '{productName, select, undefined {Der Artikel} other {{productName}}} konnte nicht vom Merkzettel entfernt werden.',
  },
  addSuccess: {
    id: 'add-to-wishlist.add-success',
    defaultMessage:
      '{productName, select, undefined {Der Artikel} other {{productName}}} wurde dem Merkzettel hinzugefügt.',
  },
  addError: {
    id: 'add-to-wishlist.add-error',
    defaultMessage:
      '{productName, select, undefined {Der Artikel} other {{productName}}} konnte nicht auf dem Merkzettel gespeichert werden.',
  },
  removeLabel: {
    id: 'add-to-wishlist.label.remove',
    defaultMessage: 'Vom Merkzettel entfernen',
  },
  addLabel: {
    id: 'add-to-wishlist.label.add',
    defaultMessage: 'Auf den Merkzettel',
  },
  removeTitle: {
    id: 'add-to-wishlist-title.remove',
    defaultMessage:
      '{ productName, select, undefined { Der Artikel } other {{ productName }}} vom Merkzettel entfernen',
  },
  addTitle: {
    id: 'add-to-wishlist-title.add',
    defaultMessage:
      '{productName, select, undefined { Der Artikel } other {{ productName }}} auf den Merkzettel legen',
  },
});

/* GraphQL */ `
  query WishlistSkus($locale: String!) {
    wishlist(locale: $locale) {
      lineItems {
        sku
      }
      count
    }
  }
`;

// TODO: refactor button to only use one sku for both tracking and api calls
/**
 * WishlistButtonWithApi connects to wishlist api, sets button state and displays a Notification on change
 */
export const WishlistButtonWithApi = ({
  sku,
  productName,
  availability,
  iconOnly = true,
  trackingProps,
  ...rest
}: WishlistButtonWithApiProps) => {
  const { formatMessage } = useIntl();
  const dispatchGTMEvent = useTracking();
  const { locale } = useI18n();
  const [{ data, fetching }] = useAuthQuery({
    query: WishlistSkusDocument,
    variables: { locale },
  });
  const [, executeAddToWishlist] = useAuthMutation(AddToWishlistDocument);
  const [, executeRemoveFromWishlist] = useAuthMutation(RemoveFromWishlistDocument);
  const [isActive, setIsActive] = useState(false);
  const [notification, setNotification] = useState<
    | {
        message: string;
        severity: NotificationProps['severity'];
      }
    | undefined
  >(undefined);

  const { products, custom, name, variantIds, wishListId } = trackingProps ?? {};

  const portalRef = useRef<HTMLElement>();

  useEffect(() => {
    portalRef.current = document.getElementById('notification-portal') || undefined;
  }, []);

  useEffect(() => {
    if (data && !fetching) {
      const wishlistSKUs = data?.wishlist?.lineItems.map((item) => item.sku) ?? [];
      setIsActive(wishlistSKUs.includes(sku));
    }
  }, [data, fetching, sku]);

  const clickHandler = async () => {
    if (isActive) {
      try {
        const result = await executeRemoveFromWishlist({ locale, sku });
        if (result.data?.removeFromWishlist) {
          setNotification({
            severity: 'warning',
            message: formatMessage(wishlistMessages.removeSuccess, { productName }),
          });
          getBridge().updateWishlist();
          dispatchGTMEvent({
            event: 'ga_event',
            eventAction: 'wishlistRemove',
            eventCategory: 'userInteraction',
            eventLabel: sku,
          });
          if (products && products.length > 0) {
            // sending undefined or an empty array will cause the event to be invalid in nitro sniffer
            dispatchGTMEvent<GTMEventGlycerinRemoveFromWishList>({
              event: 'RemoveFromWishList',
              RemoveFromWishListData: {
                removedProducts: products,
                custom,
                name,
                variantIds,
                wishListId,
              },
            });
          }
        } else {
          throw new Error();
        }
      } catch {
        setNotification({
          severity: 'error',
          message: formatMessage(wishlistMessages.removeError, { productName }),
        });
      }
    } else {
      try {
        const result = await executeAddToWishlist({
          locale,
          sku,
          unspecified: availability === 'UNSELECTED',
        });
        if (result.data?.addToWishlist) {
          setNotification({
            severity: 'success',
            message: formatMessage(wishlistMessages.addSuccess, { productName }),
          });
          getBridge().updateWishlist();
          dispatchGTMEvent({
            event: 'ga_event',
            eventAction: 'wishlistAdd',
            eventCategory: 'userInteraction',
            eventLabel: sku,
          });
          if (products && products.length > 0) {
            // sending undefined or an empty array will cause the event to be invalid in nitro sniffer
            dispatchGTMEvent<GTMEventGlycerinAddToWishList>({
              event: 'AddToWishList',
              AddToWishListData: {
                addedProducts: products,
                custom,
                name,
                variantIds,
                wishListId,
              },
            });
          }
        } else {
          throw new Error();
        }
      } catch {
        setNotification({
          severity: 'error',
          message: formatMessage(wishlistMessages.addError, { productName }),
        });
      }
    }
  };

  const wishListButtonTitle = formatMessage(
    isActive ? wishlistMessages.removeTitle : wishlistMessages.addTitle,
    { productName },
  );

  return (
    <>
      {notification && (
        <Portal container={portalRef.current}>
          <Notification
            open={!!notification}
            severity={notification.severity}
            onClose={() => setNotification(undefined)}
            autoHideDuration={2500}
          >
            {notification.message}
          </Notification>
        </Portal>
      )}
      <WishlistButton
        {...rest}
        title={wishListButtonTitle}
        isActive={isActive}
        onClick={clickHandler}
        loading={fetching}
        variant={iconOnly ? rest.variant : 'contained'}
        disableRipple={iconOnly}
        data-testid={`wishlist-button-${isActive ? 'active' : 'inactive'}`}
      >
        {iconOnly
          ? null
          : formatMessage(isActive ? wishlistMessages.removeLabel : wishlistMessages.addLabel)}
      </WishlistButton>
    </>
  );
};
