import type { ProductConfig } from './product.types';

export const productConfig: ProductConfig = {
  aplus: {
    enabled: true,
  },
  cliplister: {
    customerId: '',
    consentGroup: 'C0004',
    enabled: true,
    scriptUrl:
      'https://mycliplister.com/merge?cliplister=1.6&clviewer=1.23&videostage=1.18&innercontrols=1.7&clickablevideo=1.4&playbutton=1.1&previewimage=1.4&bufferingspinner=1.5&nextbutton=1.2&autoplaynext=1.1',
  },
  flixmedia: {
    enabled: false,
    distributorId: undefined,
  },
  hasCarbonHint: true,
  hasKIM: true,
  hasScarcity: false,
  hasSwogo: true,
  mazing: {
    enabled: true,
  },
  price: {
    hasSalePrimaryColor: true,
  },
  recommendations: {
    dv: {
      slots: {
        1: 'prudsysProducts',
        2: 'seriesProducts',
        3: 'criteoProducts',
        4: 'similarProducts',
      },
    },
    atb: {
      slots: {
        1: 'prudsysAddToBasket',
        2: 'criteoProducts',
      },
    },
  },
  reviews: {
    hintLayerId: 'hint_kdbe_verifiziert',
  },
  serviceLayer: {
    paymentMethods: null,
  },
  taxHint: {
    hideAdditionalDeliveryInfo: false,
    modalContent: {
      DEFAULT: {
        link: 'delivery_default',
      },
      L: {
        link: 'delivery_spedition-layer-L',
      },
      S: {
        link: 'delivery_spedition-layer-S',
      },
    },
  },
  voucherPrice: {
    enabled: true,
  },
};
