import type { SxProps } from '@mui/material';
import { IconButton } from '@mui/material';
import { ConditionalWrapper, Link, MenuItem, Skeleton, Typography } from '@packages/shared';
import { Adoptarrow32 } from '@packages/themes/icons';
import type { MouseEventHandler } from 'react';

interface InspiringSearchSuggestionsItemProps {
  /**
   data-testid for history item
   @default inspiring-search-item
  */
  testId?: string;
  /** Link url */
  url?: string;
  /** Click on history item */
  onClick?: MouseEventHandler<HTMLElement>;
  withAdoptButton?: boolean;
  /** Callback fired when the user clicks the arrow to the right of the item */
  onArrowClick?: () => void;
  /** Text label */
  label: string;
  /** Is entry selected */
  selected?: boolean;
  /** MUI System sx prop */
  sx?: SxProps;
}

export const InspiringSearchSuggestionsItem = ({
  testId = 'inspiring-search-item',
  url,
  onClick,
  onArrowClick,
  withAdoptButton = true,
  label,
  selected,
  sx,
}: InspiringSearchSuggestionsItemProps) => (
  // TODO do away with this weird wrapper when refactoring this and SearchHistory
  <ConditionalWrapper
    condition={!!url}
    wrapper={(ch) => (
      <Link href={url!} noLinkStyle id={selected ? 'selected-item' : ''}>
        {ch}
      </Link>
    )}
  >
    <MenuItem
      id={!url && selected ? 'selected-item' : ''}
      data-testid={testId}
      sx={{
        display: 'flex',
        alignItems: 'center',
        '.MuiTypography-body1': {
          flexGrow: 1,
        },
        height: 42,
        p: 0,
        pl: 1.75,
        ...sx,
      }}
      selected={selected}
      onMouseDown={(e) => {
        // avoid onBlur before following link
        e.preventDefault();
      }}
      onClick={onClick}
    >
      <Typography variant="body1">{label}</Typography>
      {withAdoptButton && (
        <IconButton
          size="small"
          aria-label="Inspiring search suggestion"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onArrowClick?.();
          }}
        >
          <Adoptarrow32 />
        </IconButton>
      )}
    </MenuItem>
  </ConditionalWrapper>
);

const Fallback = () => (
  <MenuItem
    sx={{
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      paddingLeft: 1.75,
      justifyContent: 'space-between',
    }}
  >
    <Skeleton variant="text" width="70%" height={20} />
    <IconButton size="small" aria-label="Inspiring search suggestion" disabled>
      <Adoptarrow32 />
    </IconButton>
  </MenuItem>
);

InspiringSearchSuggestionsItem.Fallback = Fallback;
