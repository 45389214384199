/** standard fisher-yates shuffle (https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle) */
export const shuffled = <T>(array: T[]) => {
  const shuffledArray = [...array];

  // iterate from N-1 to 1
  for (let i = array.length - 1; i > 0; i -= 1) {
    // j should be a random integer such that 0 ≤ j ≤ i
    const j = Math.floor(Math.random() * (i + 1));

    // swap elements at i and j, using a temp variable because direct swapping is annoying in JS
    const temp = shuffledArray[i];
    shuffledArray[i] = shuffledArray[j];
    shuffledArray[j] = temp;
  }

  return array;
};
