import config from '@packages/config';
import { cached } from '@packages/shared/src/utils/cached';
import { logger } from '@packages/utilities/src/logger/logger';
import { fetcher } from '../../../../utils';
import { Audiences } from '../../../../../interfaces';
import type { PromotionBannerItemType } from '../../../../../interfaces/contentSnippet';
import type { ContentSnippetApiData, Promotion } from '../../types';
import { mapDrupalContentSnippetsToPromotions } from '../mapDataToPromotion';

export const getValidPromotions = async (
  data: ContentSnippetApiData,
  platform: 'app' | 'web',
  testDate?: string,
  audience: Audiences[keyof Audiences] = Audiences.anonymous,
): Promise<(Promotion | undefined)[] | undefined> => {
  const currentDate = testDate ? new Date(testDate).getTime() : new Date().getTime();
  const filteredPromotionBanner = data?.data?.filter(
    (contentSnippet) => contentSnippet.type === 'content_snippet_bundled--promotionbanner',
  );
  if (filteredPromotionBanner?.length === 0) {
    throw new Error('no content_snippet_bundled--promotionbanner available');
  }
  // The Audiences Enum lists the customer audiences with priority from high (lower index) to low (higher index)
  // Use it as array and cut away the audiences with higher priority (lower index) than the given target audience
  const audienceArray = Object.values(Audiences) as Audiences[keyof Audiences][];
  const audiencesLeft = audienceArray.slice(
    audienceArray.findIndex((audienceFromList) => audienceFromList === audience),
  );
  const currentBannerData = filteredPromotionBanner?.filter((contentSnippet) => {
    if (contentSnippet.type !== 'content_snippet_bundled--promotionbanner') {
      return false;
    }
    /* The banner-data may be a ContentSnippet or Promotion - if ContentSnippet
    we need to dig to the promotion and consider the snippet and
    user-state validity itself */
    const {
      field_paragraph: paragraph,
      field_visible_from: snippetVisibleFrom,
      field_visible_until: snippetVisibleUntil,
      field_user_status: userStatus,
      field_display_app: displayApp,
      field_display_web: displayWeb,
    } = contentSnippet;
    const {
      field_promotion: {
        field_promotion_valid_from_date: promoValidFromDate,
        field_promotion_valid_until_date: promoValidUntilDate,
      },
    } = paragraph;
    const dateSnippetVisibleFrom = new Date(snippetVisibleFrom || currentDate - 1800).getTime();
    const dateSnippetVisibleUntil = new Date(snippetVisibleUntil || currentDate + 1800).getTime();
    const bannerValidFrom = new Date(promoValidFromDate || currentDate - 1800).getTime();
    const bannerValidUntil = new Date(promoValidUntilDate || currentDate + 1800).getTime();
    // select all promotions as ok that fit the current audience and lower priorities
    const isPromotionForLoginType =
      (userStatus &&
        'name' in userStatus &&
        userStatus.name &&
        audiencesLeft.includes(userStatus.name)) ||
      (userStatus && 'data' in userStatus && userStatus.data === null);

    return (
      isPromotionForLoginType &&
      dateSnippetVisibleFrom <= currentDate &&
      bannerValidFrom <= currentDate &&
      currentDate <= dateSnippetVisibleUntil &&
      currentDate <= bannerValidUntil &&
      (typeof displayWeb === 'undefined' ||
        (platform === 'web' && displayWeb) ||
        (platform === 'app' && displayApp))
    );
  }) as PromotionBannerItemType[];

  // after selecting all possible promotions - sort the current banner data array by the audience priorities
  currentBannerData?.sort(
    (a, b) =>
      audienceArray.findIndex((audienceName) => audienceName === a.field_user_status.name) -
      audienceArray.findIndex((audienceName) => audienceName === b.field_user_status.name),
  );

  return (
    (currentBannerData &&
      currentBannerData.length !== 0 &&
      mapDrupalContentSnippetsToPromotions(currentBannerData, testDate)) ||
    undefined
  );
};

const fetchAndProcessPromotions = async (
  fetchUrl: string,
  platform: 'app' | 'web',
  testDate?: string,
  audience?: Audiences,
) =>
  /* fetching result depends on fetchUrl
     - from bucket I get ContentSnippetApiData
     - from own API I get Promotion-Array
  */
  fetcher<ContentSnippetApiData | Promotion[]>(fetchUrl).then((data) => {
    if (Array.isArray(data)) {
      return Promise.resolve(data);
    }
    return getValidPromotions(data, platform, testDate, audience);
  });

/**
 * fetch a contentSnippet and return a set of promotions valid by:
  - shopId
  - validation times
  - customer session
    - login status
    - customer group (tbd)

 * @param filter object with properties for applied filters
 * @returns content snippet data
 */
export const fetchContentSnippetData = async (
  {
    shopId = '-1',
    locale = 'de',
    testDate,
    audience = Audiences.anonymous,
    platform = 'web',
  }: {
    shopId?: string;
    locale?: string;
    testDate?: string;
    audience?: Audiences;
    platform?: 'app' | 'web';
  } = {},
  callOwnApi?: boolean,
): Promise<(Promotion | undefined)[] | undefined> => {
  const language = locale.substring(0, 2) || 'de';
  // INSPIRE-3268 - if shopId === "" it is not covered by the default function parameter value - so set it to "-1"
  const finalShopId = shopId || '-1';

  const fetchUrlEmptyPromotions = callOwnApi
    ? `/api/promotions/content-snippet/empty-promotions/?locale=${language}&audience=${audience}&testDate=${
        testDate || ''
      }`
    : config.promotionBanner.apiEndpoints.bucketEmptyPromotions.replace('<language>', language);

  // return no data if an empty promotion should be rendered and user is anonymous
  if (audience === Audiences.anonymous) {
    const emptyPromotionsList = await cached(
      fetchUrlEmptyPromotions,
      () =>
        fetcher(fetchUrlEmptyPromotions).catch((error) => {
          // the TypeError represents a network error while the status code >= 500 is a server error
          if (error instanceof TypeError || error?.cause?.status >= 500) {
            logger.error({ error }, 'Error: Failed to fetch promotion data');
            throw error;
          }
          // its not an issue if the empty promotions list is not available
          logger.warn({ error }, 'Failed to fetch empty promotions list');
          return undefined;
        }),
      5 * 60 * 1000,
    )();
    if (Array.isArray(emptyPromotionsList) && emptyPromotionsList.includes(finalShopId)) {
      return undefined;
    }
  }

  const fetchUrl = callOwnApi
    ? `/api/promotions/content-snippet/${finalShopId}/?locale=${language}&audience=${audience}&testDate=${
        testDate || ''
      }`
    : config.promotionBanner.apiEndpoints.bucketContentSnippet
        .replace('<language>', language)
        .replace('<identifier>', finalShopId);

  const fetchFallbackUrl = callOwnApi
    ? `/api/promotions/content-snippet/-1/?locale=${language}&audience=${audience}&testDate=${
        testDate || ''
      }`
    : config.promotionBanner.apiEndpoints.bucketContentSnippet
        .replace('<language>', language)
        .replace('<identifier>', '-1');

  // await the following promises because the result "undefined" triggers the fallback
  try {
    // better debuggable way :-P
    return await cached(
      [fetchUrl, platform, testDate, audience].join(''),
      () => fetchAndProcessPromotions(fetchUrl, platform, testDate, audience),
      5 * 60 * 1000,
    )();
  } catch (error: any) {
    // the TypeError represents a network error while the status code >= 500 is a server error
    if (error instanceof TypeError || error?.cause?.status >= 500) {
      logger.error({ error }, 'Error: Failed to fetch promotion data');
      throw error;
    }
    // all other errors are just warnings
    logger.warn({ finalShopId, error }, 'Failed to fetch promotion data for shopId');
  }

  try {
    return await cached(
      [fetchFallbackUrl, platform, testDate, audience].join(''),
      () => fetchAndProcessPromotions(fetchFallbackUrl, platform, testDate, audience),
      5 * 60 * 1000,
    )();
  } catch (error: any) {
    // the TypeError represents a network error while the status code >= 500 is a server error
    if (error instanceof TypeError || error?.cause?.status >= 500) {
      logger.error({ error }, 'Error: Failed to fetch promotion data');
      throw error;
    }
    // all other errors are just warnings
    logger.warn({ error }, 'Temporarily failed to fetch promotion data');
    return undefined;
  }
};
