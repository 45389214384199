import React, { startTransition, useEffect, useState } from 'react';
import { useAbTesting } from '@packages/abtesting';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { getFragmentData } from '@packages/gql/generated/shopping';
import type { ButtonProps as MuiButtonProps, SxProps, Theme } from '@mui/material';
import { AddToBasketFragmentFragmentDoc } from '@packages/gql/generated/shopping/AddToBasketFragmentFragmentDoc';
import {
  Button,
  CircularProgress,
  DialogPreset,
  Link,
  Notification,
  useI18n,
  useSnackbar,
} from '@packages/shared';
import { Basket32 } from '@packages/themes/icons';
import { useSession, useLoginState, getLocalizedPath, useConfig } from '@packages/utilities';
import { getBridge } from '@packages/utilities/src/getBridge';
import { useAtomValue } from 'jotai';
import { type MessageDescriptor, useIntl } from 'react-intl';
import { useAuthMutation } from '@packages/gql';
import type {
  AddToBasketFragmentFragment,
  LineItemFragmentFragment,
} from '@packages/gql/generated/shopping/graphql';
import { AddToBasketDocument } from '@packages/gql/generated/shopping/AddToBasketDocument';
import { MessageFragmentFragmentDoc } from '@packages/gql/generated/shopping/MessageFragmentFragmentDoc';
import { LineItemFragmentFragmentDoc } from '@packages/gql/generated/shopping/LineItemFragmentFragmentDoc';
import { ChangeProductDocument } from '@packages/gql/generated/shopping/ChangeProductDocument';
import { selectedQuantity, selectedServices } from '../atoms';
import { getSkuPart } from '../helpers';
import { useAddToBasketTracking } from '../Tracking/hooks';
import { addToBasketMessages } from './messages';

/* GraphQL */ `
  fragment AddToBasketFragment on Product {
    akl
    sku
    marketcode
    availability {
      isOrderable
      state
    }
    price {
      currency
      value
      valueOld
      saving {
        value
      }
    }
    ...AddToBasketLayerFragment
    ...AddToBasketTrackingFragment
  }
`;

/* GraphQL */ `
  mutation AddToBasket(
    $locale: String!
    $sku: String!
    $count: Int!
    $isSpecialOrder: Boolean!
    $serviceLineitems: [AddBasketServiceLineitem!]
    $trackingParameter: BasketProductTrackingParameter
    $returnAllProducts: Boolean!
  ) {
    addToBasket(
      locale: $locale
      product: {
        sku: $sku
        count: $count
        isSpecialOrder: $isSpecialOrder
        basketOption: []
        serviceLineitems: $serviceLineitems
        trackingParameter: $trackingParameter
      }
      returnAllProducts: $returnAllProducts
    ) {
      itemCount
      success
      messages {
        ...MessageFragment
      }
      lineItems {
        ...LineItemFragment
      }
    }
  }
`;

/* GraphQL */ `
  mutation ChangeProduct(
    $locale: String!
    $sku: String!
    $lineItemId: String!
    $count: Int!
    $serviceLineitems: [AddBasketServiceLineitem!]
  ) {
    changeProduct(
      locale: $locale
      product: {
        sku: $sku
        lineItemId: $lineItemId
        count: $count
        serviceLineitems: $serviceLineitems
        basketOption: []
      }
    ) {
      success
      messages {
        ...MessageFragment
      }
    }
  }
`;

export type AddToBasketButtonProps = {
  /**
   * addToBasket fragment data
   */
  data: FragmentType<typeof AddToBasketFragmentFragmentDoc>;
  /**
   * AddToBasketLayer component
   */
  children?: (
    layerData: AddToBasketFragmentFragment,
    lineItem: LineItemFragmentFragment,
  ) => React.ReactNode;
  /**
   * Please use only for miniDV
   * If this value is undefined, it will add new product in to the basket
   * If this value is defined, it will update the current lineItem in the basket
   */
  lineItemId?: string;
  /**
   * Please use only for miniDV
   * onClose function to close the miniDV
   * If this value is undefined, it will add new product into the basket from detail view.
   * If this value is defined, and lineItemId is undefined, it will add new product into the basket from miniDV.
   * If this value is defined, and lineItemId is defined, it will update the current lineItem in the basket from miniDV.
   */
  onClose?: () => void;
  /**
   * Custom button text
   */
  customButtonText?: MessageDescriptor | null;
  /**
   * Creates unique icon theming
   */
  iconSx?: SxProps<Theme>;
  /**
   * @default false
   */
  hideEndIcon?: boolean;
} & Omit<MuiButtonProps, 'children'>;

export const AddToBasketButton = ({
  data: productData,
  children,
  lineItemId,
  onClose,
  onClick,
  customButtonText,
  sx: sxButtonOverrides,
  color = 'primary',
  size = 'large',
  fullWidth = true,
  hideEndIcon = false,
  iconSx,
}: AddToBasketButtonProps) => {
  const product = getFragmentData(AddToBasketFragmentFragmentDoc, productData);
  const {
    akl,
    availability: { state, isOrderable },
    sku,
    price,
  } = product;

  const [dialogOpened, setDialogOpened] = useState(false);
  const [openErrorNotification, setOpenErrorNotification] = useState(false);
  const [openWarningNotification, setOpenWarningNotification] = useState(false);
  // these atoms has to be outsourced into a wrapper when AddToBasketButton gets migrated into modules
  // in future this component will also be used from domain order
  const quantity = useAtomValue(selectedQuantity);
  const serviceLineitems = useAtomValue(selectedServices);

  const { formatMessage } = useIntl();
  const { loading } = useSession();
  const { loggedIn } = useLoginState();
  const { setDefaultProductOutcome } = useAbTesting();
  const config = useConfig();

  const isAvailable = state !== 'UNSELECTED' ? isOrderable : true;

  const [{ data, fetching, error }, executeAddToBasket] = useAuthMutation(AddToBasketDocument);
  const [{ fetching: miniDVFetching }, executeChangeProduct] =
    useAuthMutation(ChangeProductDocument);

  const { locale } = useI18n();
  const { addToast } = useSnackbar();

  const {
    trackLayerClosed,
    trackToBasketClick,
    trackToCheckAndOrder,
    trackAddToCart,
    trackCatalogGAddedProduct,
    trackGlycerinAddToCart,
    getTrackingParameter,
  } = useAddToBasketTracking({
    data: product,
  });

  const isLoading = loading || fetching || miniDVFetching;

  useEffect(() => {
    if (error) {
      setOpenErrorNotification(true);
    }
    if (data && dialogOpened) {
      if (!data.addToBasket.success && data.addToBasket.messages) {
        setOpenErrorNotification(true);
        setDialogOpened(false);
      } else if (data.addToBasket.success) {
        setDefaultProductOutcome(
          [
            {
              count: quantity,
              masterId: akl,
              sku: getSkuPart(sku, 'ArticleNumberAndSize'),
              value: price.value,
            },
          ],
          'ATB',
        );
        trackToCheckAndOrder();
        trackAddToCart({ lineItems: data.addToBasket.lineItems });
        trackGlycerinAddToCart();
        trackCatalogGAddedProduct();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, dialogOpened]);

  // It works only for miniDV
  const miniDVOnClickHandler = async () => {
    const result = await executeChangeProduct({
      locale,
      sku,
      lineItemId: lineItemId!,
      count: quantity,
      serviceLineitems,
    });

    if (result.error || (result.data && !result.data.changeProduct.success)) {
      addToast({
        message: formatMessage(addToBasketMessages.errorMessage),
        severity: 'error',
        duration: 5000,
        onClose: () => {},
      });
    } else if (result.data?.changeProduct) {
      addToast({
        message: formatMessage(addToBasketMessages.miniDVSuccess),
        severity: 'success',
        duration: 5000,
        onClose: () => {},
      });

      if (onClose && typeof onClose === 'function') {
        onClose();
      }
    }
  };

  const onClickHandler = async () => {
    if (state === 'UNSELECTED') {
      setOpenWarningNotification(true);
    } else {
      const result = await executeAddToBasket({
        locale,
        sku,
        count: quantity,
        isSpecialOrder: false,
        serviceLineitems,
        trackingParameter: getTrackingParameter(),
        returnAllProducts: true,
      });

      if (onClose && typeof onClose === 'function') {
        onClose();
      }

      if (result.error || (result.data && !result.data.addToBasket)) {
        setOpenErrorNotification(true);
      } else if (result.data?.addToBasket) {
        getBridge().updateBasket();
        startTransition(() => setDialogOpened(true));
      }
    }
  };

  const trackToCheckAndOrderHandler = () => {
    trackToBasketClick();
  };

  const layerCloseHandler = () => {
    startTransition(() => setDialogOpened(false));
    trackLayerClosed();
  };

  const handleButtonText = () => {
    if (isAvailable && lineItemId) {
      return addToBasketMessages.miniDVButtonText;
    }
    if (isAvailable && lineItemId === undefined) {
      return customButtonText ?? addToBasketMessages.available;
    }

    return addToBasketMessages.soldOut;
  };

  const endIcon = (() => {
    if (hideEndIcon) {
      return null;
    }
    if (isLoading) {
      return <CircularProgress color="secondary" size="s" sx={iconSx} />;
    }
    if (!isLoading && lineItemId === undefined) {
      return <Basket32 sx={{ color: 'primary.contrastText', ...iconSx }} />;
    }

    return null;
  })();

  return (
    <>
      {openWarningNotification && (
        <Notification
          open={openWarningNotification}
          severity="warning"
          autoHideDuration={5000}
          onClose={() => setOpenWarningNotification(false)}
        >
          {formatMessage(addToBasketMessages.warningMessage)}
        </Notification>
      )}
      {openErrorNotification && (
        <Notification
          open={openErrorNotification}
          severity="error"
          autoHideDuration={5000}
          onClose={() => setOpenErrorNotification(false)}
        >
          {data &&
          !data.addToBasket.success &&
          data.addToBasket.messages &&
          data.addToBasket.messages.length > 0
            ? getFragmentData(MessageFragmentFragmentDoc, data.addToBasket.messages)[0].description
            : formatMessage(addToBasketMessages.errorMessage)}
        </Notification>
      )}
      {/* If there is no onClose function, it means button was clicked from detail view page */}
      {dialogOpened && data && data.addToBasket.success && onClose === undefined && (
        <DialogPreset
          title={formatMessage(addToBasketMessages.headline)}
          maxWidth="lg"
          stickyFooter
          buttons={
            <>
              {loggedIn && (
                <Button
                  LinkComponent={Link}
                  onClick={trackToCheckAndOrderHandler}
                  href={getLocalizedPath(config.order.paths.payment, locale)}
                  color="primary"
                  size="large"
                  sx={{ maxWidth: ['100%', '100%', '180px'] }}
                  fullWidth
                >
                  {formatMessage(addToBasketMessages.toCheckout)}
                </Button>
              )}
              <Button
                LinkComponent={Link}
                onClick={trackToCheckAndOrderHandler}
                href={getLocalizedPath(config.order.paths.basket, locale)}
                color={loggedIn ? 'inherit' : 'primary'}
                size="large"
                sx={{ maxWidth: ['100%', '100%', '180px'] }}
                fullWidth
              >
                {formatMessage(addToBasketMessages.navigateToCart)}
              </Button>
              <Button
                variant="text"
                size="large"
                onClick={layerCloseHandler}
                sx={{ maxWidth: ['100%', '100%', '180px'] }}
                fullWidth
              >
                {formatMessage(addToBasketMessages.continueShopping)}
              </Button>
            </>
          }
          open={dialogOpened}
          onClose={layerCloseHandler}
        >
          {children &&
            children(
              product,
              getFragmentData(
                LineItemFragmentFragmentDoc,
                data.addToBasket.lineItems[data.addToBasket.lineItems.length - 1],
              ),
            )}
        </DialogPreset>
      )}
      <Button
        endIcon={endIcon}
        color={color}
        size={size}
        onClick={(e) => {
          onClick && onClick(e);
          lineItemId ? miniDVOnClickHandler() : onClickHandler();
        }}
        fullWidth={fullWidth}
        sx={{ whiteSpace: 'nowrap', width: '100%', ...sxButtonOverrides }}
        disabled={!isAvailable || isLoading}
      >
        {customButtonText !== null && formatMessage(handleButtonText())}
      </Button>
    </>
  );
};
