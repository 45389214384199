import { type ParsedUrlQuery } from 'querystring';

import { type Config } from '@packages/config';
import { getAllTestParticipations } from '@packages/abtesting/src/useAbTesting/getAllTestParticipations';

export const ONBOARDING_QUERY_PARAM = 'lassDichInspirieren';
export const INSPIRING_SEARCH_TEST_ID = '2024_7_SB01';

export const INSPIRING_SEARCH_V2_PREVIEW_QUERY_PARAM = 'previewV2';
export const INSPIRING_SEARCH_V2_TEST_ID =
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'staging' ? '2024_08_SB12_test' : '2024_08_SB12';

export const getTestGroup = (cookies: Record<string, string>) => {
  const activeTests = getAllTestParticipations(cookies);
  // TODO SEARCH-3032 use other test id
  const test = activeTests.find(({ testId }) => testId === INSPIRING_SEARCH_TEST_ID);
  return test?.variant?.treatment;
};

export const INSPIRING_SEARCH_V1 = 'v1' as const;
export const INSPIRING_SEARCH_V2 = 'v2' as const;

/**
 * Get the activation status for Inspiring Search features (usable on server and client)
 */
export const getInspiringSearchSettingsInternal = (
  query: ParsedUrlQuery,
  config: Config,
  testGroup?: string,
) => {
  const {
    search: { isInspiringSearchEnabled: isEnabledInConfig },
  } = config;

  const forceAiEnhancement = !!query.alwaysAi || ONBOARDING_QUERY_PARAM in query;
  const forceV2Preview = INSPIRING_SEARCH_V2_PREVIEW_QUERY_PARAM in query;

  const isPartOfTestGroup = testGroup === 'test';

  // TODO SEARCH-3032 change the test split from "no searchbuddy" vs. "searchbuddy" to "v1" vs. "v2", effectively changing this to always `true`
  const isInspiringSearchEnabled =
    (isEnabledInConfig && !isPartOfTestGroup) || !!query.enableAi || forceAiEnhancement;

  return {
    forceAiEnhancement,
    isInspiringSearchEnabled,
    // TODO SEARCH-3032 switch based on test group in addition to "force" flag
    inspiringSearchVersion: forceV2Preview ? INSPIRING_SEARCH_V2 : INSPIRING_SEARCH_V1,
  };
};

/**
 * Get the activation status for Inspiring Search features (server-side)
 */
export const getInspiringSearchSettings = (
  query: ParsedUrlQuery,
  config: Config,
  cookies: Record<string, string>,
) => {
  const testGroup = getTestGroup(cookies);

  return getInspiringSearchSettingsInternal(query, config, testGroup);
};
