import useSWR from 'swr/immutable';

import { useI18n } from '@packages/shared';
import { useConfig } from '@packages/utilities';

const getSafeStringArray = (data: unknown): string[] =>
  Array.isArray(data) && data.every((x: unknown) => typeof x === 'string') ? data : [];

export const useInspiringSearchData = () => {
  const {
    tenantId,
    search: { inspiringSearchDataBucket },
  } = useConfig();
  const { locale } = useI18n();

  const url = `${inspiringSearchDataBucket}/${tenantId}.json`;

  const { data, isLoading } = useSWR(url);

  const exampleQueries = getSafeStringArray(data?.[locale]?.exampleQueries);
  const placeholders = getSafeStringArray(data?.[locale]?.placeholders);

  return { exampleQueries, placeholders, isLoading };
};
