import deepmerge from 'deepmerge';
import type { Config, DeepPartial } from '../default/types';
import defaultConfig, { customArrayMerge, environment, getFullLocaleFromConfig } from '../default';

const tenantId = 'ackermannch';

const config = deepmerge<Config, DeepPartial<Config>>(
  defaultConfig,
  {
    host: { name: tenantId, domain: 'ackermann.ch' },
    clientId: 'AckermannCh',
    i18n: {
      defaultLocale: 'de-CH',
      locales: ['de-CH', 'fr-CH'],
      languages: ['de', 'fr'],
    },
    tenantId,
    company: {
      name: 'Ackermann',
      seoTitleName: 'Ackermann.ch',
    },
    headerSlots: {
      localeSwitcher: true,
      serviceApi: `https://storage.googleapis.com/drupal-pages/${environment}/menus/${tenantId}/<locale>/bucket-service-flyout.json`,
    },
    assets: {
      cmsImage: {
        imageOptimizationEnabled: true,
        uriTransformEndpoint: `https://storage.googleapis.com/drupal-images-${tenantId}/$1/`,
      },
    },
    staticContent: {
      apiEndpoints: {
        webappJson: `https://cms.${tenantId}.empirie.<envTld>/<locale>/<path>?_format=webapp_json&flat`,
        bucket: `https://storage.googleapis.com/drupal-pages/${environment}<subfolder>/${tenantId}/<locale>/<identifier>.json`,
      },
      ssg: {
        prerenderAtBuildTime: [
          { params: { cmsPage: ['storefront'] }, locale: 'de' },
          { params: { cmsPage: ['storefront'] }, locale: 'fr' },
          { params: { cmsPage: ['service-hilfe'] }, locale: 'de' },
          { params: { cmsPage: ['aide-service'] }, locale: 'fr' },
        ],
      },
      cmsFontToTheme: {
        '.PantonBold, .font-normal-700': {
          fontWeight: 'fontWeightBold',
        },
        '.PantonBlack, .font-normal-800': {
          fontWeight: 'fontWeightBlack',
        },
      },
    },
    product: {
      cliplister: {
        customerId: '157279',
      },
      flixmedia: {
        distributorId: 18443,
        enabled: true,
      },
      hasScarcity: true,
    },
    installmentCalculator: {
      hasLegalHints: false,
      InstallmentCalculatorCallToActionLayout: {
        layout: 'Layout3',
        openCalculatorIn: { type: 'Modal' },
      },
      hasSpecialCreditColors: true,
    },
    search: {
      adServer: {
        mode: 'containerOnly',
      },
    },
    order: {
      seals: {},
      assets: {
        premium: {
          getPremiumImage: ({ language }: { language: string }) =>
            `https://bilder.ackermann.ch/empiriecom/io/ackermannch/${language}/Header_Service_Vorteile_Treuepaeckli.svg`,
          getPremiumIcon: () =>
            'https://bilder.ackermann.ch/empiriecom/io/ackermannch/de/premiumicon.svg',
        },
        gifs: {
          success: {
            url: 'https://bilder.ackermann.ch/i/empiriecom/io/de/thank_you_1_transparent.gif',
          },
        },
      },
    },
    footer: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-pages/${environment}<subfolder>/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    forms: {
      apiAgnitasUrl: {
        values: {
          useDOI: false,
          sendAdditionalSubscribe: true,
        },
      },
      contact: {
        selectOptionsSubject: [
          { label: 'forms.type.subject.option.articleError', value: 'articleError' },
          { label: 'forms.type.subject.option.changed.email', value: 'emailChanged' },
          { label: 'forms.type.subject.option.changed.data', value: 'dataChanged' },
          { label: 'forms.type.subject.option.account', value: 'account' },
          { label: 'forms.type.subject.option.retoure', value: 'retoure' },
          { label: 'forms.type.subject.option.product', value: 'product' },
          { label: 'forms.type.subject.option.delivery', value: 'delivery' },
          { label: 'forms.type.subject.option.web', value: 'web' },
          { label: 'forms.type.subject.option.complaint.comment', value: 'complaint' },
          { label: 'forms.type.subject.option.return.data', value: 'return' },
          { label: 'forms.type.subject.option.coupons', value: 'coupons' },
          { label: 'forms.type.subject.option.newsletter', value: 'newsletter' },
          { label: 'forms.type.subject.option.other', value: 'other' },
        ],
      },
      newsletterUnsubscribe: {
        useDedicatedSubmitButtonTitleFor: ['unsubscribe|99'],
        fields: [
          {
            type: 'newsletter',
            formType: 'radio',
            defaultValue: 'unsubscribe|99',
            options: [
              {
                id: 'frq-daily',
                value: 'frequency|0',
                messageKey: 'forms.newsletter.unsubscribe.type.frequency.option.daily',
              },
              {
                id: 'frq-weekly',
                value: 'frequency|1',
                messageKey: 'forms.newsletter.unsubscribe.type.frequency.option.weekly',
              },
              {
                id: 'unsubscribe',
                value: 'unsubscribe|99',
                messageKey: 'forms.newsletter.unsubscribe.type.unsubscribe',
              },
              {
                id: 'pause',
                value: 'pause|-100',
                messageKey: 'forms.newsletter.unsubscribe.type.pause',
              },
            ],
            additionalForm: {
              id: 'pause',
              formType: 'select',
              showOnlyWhenWhatSelected: 'pause',
              messageKeyPlaceholder: 'forms.newsletter.unsubscribe.type.pause.label',
              options: [
                {
                  id: 'pause-7',
                  value: '7',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.7',
                },
                {
                  id: 'pause-14',
                  value: '14',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.14',
                },
                {
                  id: 'pause-21',
                  value: '21',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.21',
                },
                {
                  id: 'pause-30',
                  value: '30',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.30',
                },
              ],
            },
          },
        ],
      },
      newsletterSubscribe: {
        de: {
          unsubscribeLink: 'https://www.ackermann.ch/service-hilfe/vorteile/newsletter-abmelden',
          voucherLink: 'https://www.ackermann.ch/service-hilfe/vorteile/gutscheine-rabatte/',
        },
        fr: {
          unsubscribeLink:
            'https://www.ackermann.ch/fr/aide-service/avantages/desinscription-de-la-newsletter',
          voucherLink: 'https://www.ackermann.ch/fr/aide-service/avantages/bons-rabais/',
        },
      },
    },
    banner: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    nlSheetTexts: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/nl-sheet.json`,
      },
    },
    serviceLayer: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/<identifier>.json`,
        bucketPages: `https://storage.googleapis.com/drupal-pages/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    seoContent: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/inspire-seofiles/<locale>_${tenantId}-<category>.json`,
      },
    },
    survey: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-surveys/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    promotionBanner: {
      apiEndpoints: {
        bucketEmptyPromotions: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<language>/empty-promotion.json`,
        bucketPromotion: `https://storage.googleapis.com/promotion-data/${tenantId}/<language>/<code>.json`,
        bucketContentSnippet: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<language>/<identifier>.json`,
      },
    },
    voucher: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/promotion-data/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    appBanner: {
      appUrl: 'https://app.adjust.com/3fy9f3p_t6shmz5',
    },
    tracking: {
      soastaId: 'QB55A-QDUKY-23T2L-SWCLT-57YSK',
      tagmanagerId: 'GTM-MZZBD9K',
      googleSiteVerificationID: ['-KF2ltJciuiR-I1bRrpPYNZwB6h0nZji50jQpYgroRc'],
      msvalidateID: '4067315BE5669CD6C74BDEC6F6AC5D47',
      pinterestRichPinVerificationID: 'add46bfb6e0cf176e43e61b50007f9f6',
      schemaOrgOrganisation: {
        'de-CH': {
          addressCountry: 'CH',
          addressRegion: 'ZH',
          addressLocality: 'Otelfingen',
          alternateName: ['Ackermann', 'Ackermann Versand', 'Ackermannversand'],
          areaServed: 'CH',
          contactEmail: 'service@ackermann.ch',
          contactUrl: 'https://www.ackermann.ch/service-hilfe/ueber-uns/kontakt',
          description:
            'Ackermann - das echte Leben. Shoppen Sie schnell, sicher und bequem im schweizer Online Shop für Mode, Möbel und Technik',
          email: 'service@ackermann.ch',
          hoursAvailable: ['Mo-Su 7:00-22:00'],
          legalName: 'Ackermann Vertriebs AG',
          logo: `/assets/logos/${tenantId}/logo.svg`,
          name: 'Ackermann',
          postalCode: '8112',
          privacyNoticePath: '/service-hilfe/ueber-uns/datenschutz',
          potentialAction: {
            target: 'https://www.ackermann.ch/s/',
          },
          productSupported: '',
          sameAs: [
            'https://www.facebook.com/ackermannversand',
            'https://www.youtube.com/user/ackermannversandCH',
            'https://de.wikipedia.org/wiki/Ackermann_(Versand)',
          ],
          streetAddress: 'Industriestrasse 19',
          telephone: '+41-848-85-85-11',
          url: 'https://www.ackermann.ch',
        },
        'fr-CH': {
          addressCountry: 'CH',
          addressRegion: 'ZH',
          addressLocality: 'Otelfingen',
          alternateName: ['Ackermann', 'Ackermann Versand', 'Ackermannversand'],
          areaServed: 'CH',
          contactEmail: 'service@ackermann.ch',
          contactUrl: 'https://www.ackermann.ch/fr/aide-service/qui-sommes-nous/mentions-legales',
          description:
            'Ackermann - la vraie vie. Achetez rapidement, en toute sécurité et confortablement dans la boutique en ligne suisse de mode, de mobilier et de technologie.',
          email: 'service@ackermann.ch',
          hoursAvailable: ['Mo-Su 7:00-22:00'],
          legalName: 'Ackermann Vertriebs AG',
          logo: `/assets/logos/${tenantId}/logo.svg`,
          name: 'Ackermann',
          postalCode: '8112',
          privacyNoticePath: '/fr/aide-service/qui-sommes-nous/protection-des-donnees',
          potentialAction: {
            target: 'https://www.ackermann.ch/s/',
          },
          productSupported: '',
          sameAs: [
            'https://www.facebook.com/ackermannversand',
            'https://www.youtube.com/user/ackermannversandCH',
            'https://de.wikipedia.org/wiki/Ackermann_(Versand)',
          ],
          streetAddress: 'Industriestrasse 19',
          telephone: '+41-848-85-85-21',
          url: 'https://www.ackermann.ch',
        },
      },
    },
    externalScript: {
      kuecheCo: {
        key: 'pAYrueynYRcqna7lGLoVT39kF83Wy3PYENp81Yw0QJqeTyzAjXvc1FwGOzCm',
      },
    },
    headerInformation: {
      de: {
        description:
          'Ackermann - Schweizer Online Shop für Mode, Möbel u. Technik | Gratis 3 Jahre Garantie ✓ Gratis Versand ab CHF 99.- ✓ auf Rechnung und in Raten bestellen',
        title: 'Ackermann Versand Schweiz | Mode - Möbel - Technik online kaufen',
        canonical: 'https://www.ackermann.ch',
        robots: 'index,follow',
      },
      fr: {
        description:
          "Ackermann VPC - Votre boutique de mode, vêtements, chaussures et meubles pour vos achats en ligne à prix avantageux. Commander 24h/24, à l'aise depuis chez soi, ...",
        title: 'Boutique mode | Vêtements | Meubles acheter Ackermann VPC',
        canonical: 'https://www.ackermann.ch',
        robots: 'index,follow',
      },
    },
    // kim is renamed as "customerCentricFeedback"
    customerCentricFeedback: {
      tenantId: '1400',
    },
    dynamicYieldTracking: {
      key: '656c2ade1a744c7b932774a6b8c7012ac01aa46358d787f92bfd2058a97870a5',
    },
    geoCountryLayer: {
      enabled: true,
      flag: 'ch',
      alternatives: [
        {
          flag: 'de',
          shops: [
            {
              link: 'https://www.baur.de',
            },
          ],
        },
        {
          flag: 'at',
          shops: [
            {
              link: 'https://www.universal.at',
            },
          ],
        },
      ],
    },
    priceFormat: 'NoDelimiterCurrencyAtTheEnd',
  },
  { arrayMerge: customArrayMerge },
);

export const getFullLocale = (localeOrLanguage: string | undefined) =>
  getFullLocaleFromConfig(localeOrLanguage, config);

// eslint-disable-next-line import/no-default-export
export default config;
